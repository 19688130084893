import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtFmIntegerField from '../first/ftFmIntegerField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmTimeField from '../first/ftFmTimeField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtFmSearchField from '../first/ftFmSearchField';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import FtFmUploadImage from '../first/ftFmUploadImage';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup                          from '../models/genLookup';
import Pastor                             from '../models/pastor';

//********************************************************************
// PagePastorEdit
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PagePastorEdit( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { pastorId } = nav.params ?? { pastorId: 0 };    // unpack parameters passed from calling page
    const [ popup        , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy         , setBusy         ] = useState( false );
    const [ mdlPastor    , setMdlPastor    ] = useState<Pastor|null>( null );
    const [ lkpAssembly  , setLkpAssembly  ] = useState<GenLookup[]>( [] );
    const [ lkpRegion    , setLkpRegion    ] = useState<GenLookup[]>( [] );
    const [ photozKey    , setPhotozKey    ] = useState<string>( Ft.randomSha256() );
    const [ mdlUploads   , setMdlUploads   ] = useState<any>( { photoz_key : null } );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );


    //====================================================================
    // render

    if ( busy || Ft.eAny( mdlPastor, lkpAssembly, lkpRegion, Ut.lkpGender ) ) return ftBusy( { popup: popup } );

    const subhead: any = {
        ss: '12|0:6:6|0:4:8|0:4:8|0:4:8',
        txtDescr: '20,WCO#ffffff',
        bkgClr: '#e32727',
        pad: '5',
    };

    const justStl: any = {
        bor: mdlPastor!.justice_case_active ? '5,#ff0000' : '5,#008000',
        labSs: '0|6:4|6:2|6:2|6:2',
    };

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text={(pastorId == 0 ? 'New' : 'Edit') + ' Pastor'} {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlPastor, m2: mdlUploads }}
                onSubmit={onSave}
            >
                <FtFmCheckboxField name='m.justice_case_active'                label='Justice Case Active'                {...Th.field} {...justStl} />
                <FtText {...subhead} text='Personal Information' />

                <FtCon md='rw' lt='a,a' ss='12|12|6|6|6'>
                    <FtFmDropdownField name='m.title_id'                       label='Title'                   ftreq      {...Th.field12} data={Ut.lkpPastorTitle} />
                    <FtFmTextField     name='m.first_name'                     label='Full Names'                         {...Th.fieldA} />
                    <FtFmTextField     name='m.surname'                        label='Surname'                 ftreq      {...Th.fieldA} />
                    <FtFmTextField     name='m.initials'                       label='Initials'                           {...Th.field12} />
                    <FtFmTextField     name='m.idno'                           label='ID Number'                          {...Th.field12} />
                    <FtFmTextField     name='m.maiden_name'                    label='Maiden Name'                        {...Th.fieldA} />
                    <FtFmTextField     name='m.birth_place'                    label='Birth Place'                        {...Th.fieldA} />
                    <FtFmDateField     name='m.birth_date'                     label='Birth Date'                         {...Th.field12} />
                    <FtFmDateField     name='m.death_date'                     label='Deceased Date'                      {...Th.field12} />
                    <FtFmDropdownField name='m.gender_id'                      label='Gender'                             {...Th.field12} data={Ut.lkpGender} />
                    <FtFmDropdownField name='m.marital_status_id'              label='Marital Status'                     {...Th.field12} data={Ut.lkpMaritalStatus} />
                </FtCon>

                <FtCon md='rw' lt='a,a' ss='12|12|6|6|6'>
                    <FtFmUploadImage   name='m2.photoz_key'                    label='Pastor Photo'                       {...Th.field12} ss='6' uploadKey={photozKey} onError={onError} />
                </FtCon>

                <FtFmDateField     name='m.ordained_date'                      label='Ordination Date'                    {...Th.field} />
                <FtFmDateField     name='m.expiry_date'                        label='Expiry Date'                        {...Th.field} />

                <FtFmTextField     name='m.pastor_status'                      label='Pastor Status'                      {...Th.fieldC} />
                <FtSpacer h={20} />

                <FtText {...subhead} text='Contact Information' />

                <FtFmTextField     name='m.celno'                              label='Cell No'                            {...Th.fieldC} />
                <FtFmTextField     name='m.phone_work'                         label='Work Phone'                         {...Th.fieldC} />
                <FtFmTextField     name='m.email'                              label='Email'                              {...Th.fieldB} />
                <FtSpacer h={20} />

                <FtText {...subhead} text='Assembly Information' />

                <FtFmSearchField   name='m.assembly_id'                        label='Assembly'                           {...Th.fieldB} data={lkpAssembly} />
                <FtSpacer h={20} />

                <FtText {...subhead} text='Address Information' />

                <FtFmTextField     name='m.physical_adr_1'                     label='Physical Address'                   {...Th.fieldB} />
                <FtFmTextField     name='m.physical_adr_2'                     label=''                                   {...Th.fieldB} />
                <FtFmTextField     name='m.country'                            label='Country'                            {...Th.field} />
                <FtFmTextField     name='m.physical_adr_code'                  label='Code'                               {...Th.field} />
                <FtFmTextField     name='m.physical_adr_city'                  label='City/Town/Village'                  {...Th.fieldC} />
                <FtSpacer h={10} />

                <FtFmTextField     name='m.postal_adr_1'                       label='Postal Address'                     {...Th.fieldB} />
                <FtFmTextField     name='m.postal_adr_2'                       label=''                                   {...Th.fieldB} />
                <FtFmTextField     name='m.postal_adr_country'                 label='Country'                            {...Th.field} />
                <FtFmTextField     name='m.postal_adr_code'                    label='Code'                               {...Th.field} />
                <FtFmTextField     name='m.postal_adr_city'                    label='City/Town/Village'                  {...Th.fieldC} />
                <FtSpacer h={20} />

                <FtText {...subhead} text='Spouse Information' />

                <FtFmTextField     name='m.spouse_first_name'                  label='First Name'                         {...Th.field} />
                <FtFmTextField     name='m.spouse_initials'                    label='Initials'                           {...Th.field} />

                <FtFmTextField     name='m.spouse_maiden_name'                 label='Maiden Name'                        {...Th.field} />
                <FtFmTextField     name='m.spouse_personal_title'              label='Title'                              {...Th.field} />

                <FtFmTextField     name='m.spouse_surname'                     label='Surname'                            {...Th.fieldB} />
                <FtFmTextField     name='m.spouse_idno'                        label='ID Number'                          {...Th.fieldC} />

                <FtFmDateField     name='m.spouse_birth_date'                  label='Birth Date'                         {...Th.field} />
                <FtFmDateField     name='m.spouse_death_date'                  label='Death Date'                         {...Th.field} />
                <FtFmDateField     name='m.wedding_date'                       label='Marriage Date'                      {...Th.field} />
                <FtFmIntegerField  name='m.children_count'                     label='Children Count'                     {...Th.field} />

                <FtSpacer h={20} />
                <FtFmTextareaField name='m.notes'                              label='Comments'                           {...Th.fieldLgB} lines={15} />
                <FtSpacer h={20} />

                <FtText {...subhead} text='Office Use Only' />

                <FtFmDropdownField name='m.position_id'                        label='Position'                           {...Th.field} data={Ut.lkpPastorPosition} />

                <FtFmDateField     name='m.called_date'                        label='Called Date'                        {...Th.field} />
                <FtFmDateField     name='m.accepted_date'                      label='Accepted Date'                      {...Th.field} />
                <FtFmDateField     name='m.seconded_date'                      label='Seconded Date'                      {...Th.field} />
                <FtFmDateField     name='m.application_date'                   label='Application Date'                   {...Th.field} />

                <FtFmDateField     name='m.special_start_date'                 label='Special Start Date'                 {...Th.field} />
                <FtFmDateField     name='m.special_end_date'                   label='Special End Date'                   {...Th.field} />

                <FtSpacer h={50} />

                <FtText {...subhead} text='Unsorted Fields' />

                <FtFmTextField     name='m.personal_title'                     label='Personal Title'                     {...Th.field} />
                <FtFmTextField     name='m.member_no'                          label='Member No'                          {...Th.field} />
                <FtFmSearchField   name='m.region_id'                          label='Region'                             {...Th.field} data={lkpRegion} />

                <FtFmTextField     name='m.phone_home'                         label='Phone Home'                         {...Th.field} />
                <FtFmTextField     name='m.fax_home'                           label='Fax Home'                           {...Th.field} />
                <FtFmTextField     name='m.phone_extension'                    label='Phone Extension'                    {...Th.field} />
                <FtFmCheckboxField name='m.phone_request_line'                 label='Phone Request Line'                 {...Th.field} />
                <FtFmTextField     name='m.spouse_member_no'                   label='Spouse Member No'                   {...Th.field} />
                <FtFmTextField     name='m.spouse_birth_place'                 label='Spouse Birth Place'                 {...Th.field} />
                <FtFmTextareaField name='m.spouse_talents'                     label='Spouse Talents'                     {...Th.fieldLg} />
                <FtFmTextareaField name='m.instruments'                        label='Instruments'                        {...Th.fieldLg} />
                <FtFmIntegerField  name='m.pastid'                             label='Pastid'                             {...Th.field} />
                <FtFmTextField     name='m.gender_name'                        label='Gender Name'                        {...Th.field} />
                <FtFmTextField     name='m.assembly_name'                      label='Assembly Name'                      {...Th.field} />
                <FtFmTextField     name='m.assembly_no'                        label='Assembly No'                        {...Th.field} />
                <FtFmTextField     name='m.region_no'                          label='Region No'                          {...Th.field} />
                <FtFmTextField     name='m.marital_status'                     label='Marital Status'                     {...Th.field} />
                <FtFmDateField     name='m.fulltime_date'                      label='Fulltime Date'                      {...Th.field} />
                <FtFmDateField     name='m.marriage_officiant_date'            label='Marriage Officiant Date'            {...Th.field} />
                <FtFmTextField     name='m.q_number'                           label='Q Number'                           {...Th.field} />
                <FtFmDateField     name='m.legitimize_date'                    label='Legitimize Date'                    {...Th.field} />
                <FtFmDateField     name='m.preachers_panel_tv_date'            label='Preachers Panel Tv Date'            {...Th.field} />
                <FtFmDateField     name='m.preachers_panel_radio_date'         label='Preachers Panel Radio Date'         {...Th.field} />
                <FtFmTextField     name='m.language_preference'                label='Language Preference'                {...Th.field} />
                <FtFmDateField     name='m.input_date'                         label='Input Date'                         {...Th.field} />
                <FtFmTextField     name='m.position_no'                        label='Position No'                        {...Th.field} />


                <FtFmTextField     name='m.file_date'                          label='File Date'                          {...Th.field} />
                <FtFmCheckboxField name='m.is_filed'                           label='Is Filed'                           {...Th.field} />
                <FtFmTextField     name='m.move_no'                            label='Move No'                            {...Th.field} />
                <FtFmDateField     name='m.last_updated'                       label='Last Updated'                       {...Th.field} />
                <FtFmTimeField     name='m.last_updated'                       label='Last Updated'                       {...Th.field} />
                <FtFmDateField     name='m.add_change_date'                    label='Add Change Date'                    {...Th.field} />
                <FtFmTimeField     name='m.add_change_date'                    label='Add Change Date'                    {...Th.field} />
                <FtFmCheckboxField name='m.preferred_addressee'                label='Preferred Addressee'                {...Th.field} />
                <FtFmCheckboxField name='m.ge_comms'                           label='Ge Comms'                           {...Th.field} />

                <FtFmTextField     name='m.file_no'                            label='File No'                            {...Th.field} />
                <FtFmTextField     name='m.bin'                                label='Bin'                                {...Th.field} />
                <FtFmTextField     name='m.seconded_to'                        label='Seconded To'                        {...Th.field} />
                <FtFmDateField     name='m.seconded_report_date'               label='Seconded Report Date'               {...Th.field} />
                <FtFmTextField     name='m.last_edited'                        label='Last Edited'                        {...Th.field} />
                <FtFmDateField     name='m.field3_date'                        label='Field3 Date'                        {...Th.field} />
                <FtFmTextField     name='m.pastor_own'                         label='Pastor Own'                         {...Th.field} />
                <FtSpacer h={0} />
                <FtFmTextareaField name='m.field1_text'                        label='Field1 Text'                        {...Th.fieldLg} />
                <FtFmTextareaField name='m.field2_num'                         label='Field2 Num'                         {...Th.fieldLg} />
                <FtFmTextareaField name='m.field_text2'                        label='Field Text2'                        {...Th.fieldLg} />
                <FtFmTextareaField name='m.field_text3'                        label='Field Text3'                        {...Th.fieldLg} />
                <FtFmTextareaField name='m.field_text4'                        label='Field Text4'                        {...Th.fieldLg} />
                <FtFmTextareaField name='m.field_text5'                        label='Field Text5'                        {...Th.fieldLg} />
                <FtFmTextareaField name='m.remarks'                            label='Remarks'                            {...Th.fieldLg} />

                <FtFmTextareaField name='m.special_reason'                     label='Special Reason'                     {...Th.fieldLg} />

                <FtSpacer />
                <FtFmSubmit ss='3|2:2' label='Save' {...Th.submit} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        //- load lookups -----
        setBusy( true );
        Ut.loadLookups( {
            success: ( result, data ) => setBusy( false ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });
        FtRapi.callList( 'assemblyLookup', {
            success: ( result, data ) => {
                setLkpAssembly( data[0] );
                setLkpRegion( data[1] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });

        //- init record -----
        if ( pastorId != 0 ) {  // existing record
            FtRapi.callRead( 'pastorRead', pastorId, {
                success: ( result, data ) => {
                    mdlUploads.photoz_key = data[1]?.repo_key ?? null;
                    setMdlPastor( data[0] );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            });
        } else {             // new record
            setMdlPastor( new Pastor() );
        }
    }

    function onSave(): void
    {
        setBusy( true );
        if ( pastorId != 0 ) {  // existing record
            FtRapi.callUpdate( 'pastorUpdate', pastorId, {
                urlParms: mdlUploads.photoz_key ? { photoz_key : mdlUploads.photoz_key } : null,
                inRec: mdlPastor,
                success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        } else {             // new record
            FtRapi.callNew( 'pastorNew', {
                urlParms: mdlUploads.photoz_key ? { photoz_key : mdlUploads.photoz_key } : null,
                inRec: mdlPastor,
                success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        }
    }

    function onError( message: string ): void
    {
        ftPopupMessage( setPopup, "Error", message );
    }

}

/*
=== HIDDEN FIELDS ========================================================================

*/