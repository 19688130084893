import 'react-native-gesture-handler';
import React, { useState, useEffect } from 'react';
import { useURL } from "expo-linking";
import FtAppInfo from './source/first/ftAppInfo';
import FtNavScreens from './source/first/ftNavScreens';
import appTheme from './source/general/theme';
import Models from './source/models';
import Ft from './source/first/ft';

//- startups -----
import FragHeader                             from './source/fragments/fragHeader';
import PageLanding                            from "./source/pages/pageLanding";
import PageAuthLogin                          from "./source/pages/pageAuthLogin";
import PageMenu                               from "./source/pages/pageMenu";
import PageMenuPastor                         from "./source/pages/pageMenuPastor";
import PageMenuAssembly                       from "./source/pages/pageMenuAssembly";
import PageMenuRegion                         from "./source/pages/pageMenuRegion";
import PageMenuConfRegister                   from "./source/pages/pageMenuConfRegister";
import PageMenuConfReception                  from "./source/pages/pageMenuConfReception";
import PageMenuVoting                         from "./source/pages/pageMenuVoting";
//- system module -----
import PageUserSearch                         from "./source/pages/pageUserSearch";
import PageUserEdit                           from "./source/pages/pageUserEdit";
import PageTaskSearch                         from "./source/pages/pageTaskSearch";
import PageTaskEdit                           from "./source/pages/pageTaskEdit";
//- pastors modules -----
import PagePastorSearch                       from "./source/pages/pagePastorSearch";
import PagePastorEdit                         from "./source/pages/pagePastorEdit";
import PagePastorSearchMob                    from "./source/pages/pagePastorSearchMob";
import PagePastorDetailMob                    from "./source/pages/pagePastorDetailMob";
import PageQualificationSearch                from "./source/pages/pageQualificationSearch";
import PageQualificationEdit                  from "./source/pages/pageQualificationEdit";
import PagePastorFileSearch                   from "./source/pages/pagePastorFileSearch";
import PagePastorFileEdit                     from "./source/pages/pagePastorFileEdit";
import PageReportPastorMaster                 from "./source/pages/pageReportPastorMaster";
import PageReportPastor40Year                 from "./source/pages/pageReportPastor40Year";
import PageReportPastoralStatus               from "./source/pages/pageReportPastoralStatus";
import PageReportPastoralStatusLapsed         from "./source/pages/pageReportPastoralStatusLapsed";
import PageReportPastoralStatusLapsedWarning  from "./source/pages/pageReportPastoralStatusLapsedWarning";
import PageReportPastorDuplicates             from "./source/pages/pageReportPastorDuplicates";
import PageReportPastorGender                 from "./source/pages/pageReportPastorGender";
import PageReportPastorMarriageOfficers       from "./source/pages/pageReportPastorMarriageOfficers";
import PageReportPastorMovementAccepted       from "./source/pages/pageReportPastorMovementAccepted";
import PageReportPastorMovementCalled         from "./source/pages/pageReportPastorMovementCalled";
import PageReportPastorReinstated             from "./source/pages/pageReportPastorReinstated";
import PageReportPastorRenewed                from "./source/pages/pageReportPastorRenewed";
import PageReportPastorSecondedAll            from "./source/pages/pageReportPastorSecondedAll";
import PageReportPastorSecondedForces         from "./source/pages/pageReportPastorSecondedForces";
import PageReportPastorRetire                 from "./source/pages/pageReportPastorRetire";
//- assemblies-regions module -----
import PageAssemblySearch                     from "./source/pages/pageAssemblySearch";
import PageAssemblyEdit                       from "./source/pages/pageAssemblyEdit";
import PagePropertySearch                     from "./source/pages/pagePropertySearch";
import PagePropertyEdit                       from "./source/pages/pagePropertyEdit";
import PageRegionSearch                       from "./source/pages/pageRegionSearch";
import PageRegionEdit                         from "./source/pages/pageRegionEdit";
import PageReportAssemblyMaster               from "./source/pages/pageReportAssemblyMaster";
import PageReportAssemblyAddedLast6Months     from "./source/pages/pageReportAssemblyAddedLast6Months";
import PageReportAssemblyNoPastors            from "./source/pages/pageReportAssemblyNoPastors";
import PageReportAssemblyNumberList           from "./source/pages/pageReportAssemblyNumberList";
import PageReportAssemblyNumberListSum        from "./source/pages/pageReportAssemblyNumberListSum";
import PageReportAssemblyPastorsList          from "./source/pages/pageReportAssemblyPastorsList";
import PageReportLabelAssemblySecretary       from "./source/pages/pageReportLabelAssemblySecretary";
import PageReportLabelNoMailAddresses         from "./source/pages/pageReportLabelNoMailAddresses";
import PageReportRegionList                   from "./source/pages/pageReportRegionList";
import PageReportRegionOfficeBearers          from "./source/pages/pageReportRegionOfficeBearers";
//- justice module -----
import PageJusticeSearch                      from "./source/pages/pageJusticeSearch";
import PageJusticeEdit                        from "./source/pages/pageJusticeEdit";
import PageJusticeNoteSearch                  from "./source/pages/pageJusticeNoteSearch";
import PageJusticeNoteEdit                    from "./source/pages/pageJusticeNoteEdit";
import PageJusticeFileSearch                  from "./source/pages/pageJusticeFileSearch";
import PageJusticeFileEdit                    from "./source/pages/pageJusticeFileEdit";
//- conf registration module -----
import PageAttendeeNewOnline                  from "./source/pages/pageAttendeeNewOnline";
import PageAttendeeSearch                     from "./source/pages/pageAttendeeSearch";
import PageAttendeeEdit                       from "./source/pages/pageAttendeeEdit";
import PageConfregisStatistics                from "./source/pages/pageConfregisStatistics";
import PageConfregisCards                     from './source/pages/pageConfregisCards';
import PageConfregisCardsExtra                from './source/pages/pageConfregisCardsExtra';
import PageReportConfregisSummaryTotal        from "./source/pages/pageReportConfregisSummaryTotal";
import PageReportConfregisAttendees           from "./source/pages/pageReportConfregisAttendees";
import PageReportConfregisByRegion            from "./source/pages/pageReportConfregisByRegion";
import PageReportConfregisByType              from "./source/pages/pageReportConfregisByType";
import PageReportConfregisByRegionNoId        from "./source/pages/pageReportConfregisByRegionNoId";
import PageReportConfregisQuorumDetail        from "./source/pages/pageReportConfregisQuorumDetail";
import PageReportConfregisQuorumTotal         from "./source/pages/pageReportConfregisQuorumTotal";
import PageReportConfregisSummary             from "./source/pages/pageReportConfregisSummary";
//- conf reception module -----
import PageConfrecepStatistics                from "./source/pages/pageConfrecepStatistics";
import PageConfrecepScan                      from "./source/pages/pageConfrecepScan";
import PageConfrecepGoodyScan                 from "./source/pages/pageConfrecepGoodyScan";
import PageReportConfrecepSummary             from "./source/pages/pageReportConfrecepSummary";
import PageReportConfrecepDetail              from "./source/pages/pageReportConfrecepDetail";
//- voting module -----
import PageVoteProcessManager                 from './source/pages/pageVoteProcessManager';
import PageVoteCaptureNomin                   from './source/pages/pageVoteCaptureNomin';
import PageVoteApprovalPrimary                from './source/pages/pageVoteApprovalPrimary';
import PageVoteApprovalSecondary              from './source/pages/pageVoteApprovalSecondary';
import PageVoteApprovalFinal                  from './source/pages/pageVoteApprovalFinal';
import PageVoteDisplayResults                 from './source/pages/pageVoteDisplayResults';
import PageVoteDisplayStatus                  from './source/pages/pageVoteDisplayStatus';
import PageReportVoteResults                  from './source/pages/pageReportVoteResults';

Models.addModelsClassMap();

//********************************************************************
// App: application entry point
export default function App(): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    const urlHook = useURL();
    const [ init, setInit ] = useState<boolean>( false );
    const [ startPage, setStartPage ] = useState<string>( 'Landing' ); // Landing , PastorSearch, AssemblySearch

    useEffect( (): void => {
        FtAppInfo.staticInit({
            isDebug: false,
            version: '000202280107',
            orien: 'A',
            appId: '76f5a1a73802793ae2b2b76a4f045d206e6da78d304f89a551c5c738772a1bc05cf38171ff17f82ec657c5b048807ea4fbd56ba67f18f62f84f5c0a3cd4d8ce5',
            baseUrlDebug: 'https://localhost:7137/',
            baseUrlRelease: 'https://afminfosystem.azurewebsites.net/',
            title: 'Afmsa Information System',
        })
        .then( () => setInit( true ) );
    }, []);

    //====================================================================
    // render

    if ( !init ) return (<></>);

    const url = Ft.parseUrl( urlHook );

    //- detect special pages from url -----
    if ( url.parms.hasOwnProperty( 'r' ) && url.parms.r == 'confRegister' && startPage != 'AttendeeNewOnline' )
        setStartPage( 'AttendeeNewOnline' );
    else if ( url.parms.hasOwnProperty( 'r' ) && url.parms.r == 'paymentSuccess' && startPage != 'AttendeeNewOnline' )
        setStartPage( 'AttendeeNewOnline' );

    //- render page -----
    return (<FtNavScreens
        theme={appTheme}
        init={startPage}
        header={p => <FragHeader {...p} />}
        screens={[
            //- startups -----
            { name: 'Landing'                            , title: 'AFM of SA Information System'           , comp: p => (<PageLanding                             {...p} />) },
            { name: 'AuthLogin'                          , title: 'Log Into System'                        , comp: p => (<PageAuthLogin                           {...p} />) },
            { name: 'Menu'                               , title: 'Home'                                   , comp: p => (<PageMenu                                {...p} />) },
            { name: 'MenuPastor'                         , title: 'Pastors Menu'                           , comp: p => (<PageMenuPastor                          {...p} />) },
            { name: 'MenuAssembly'                       , title: 'Assemblies Menu'                        , comp: p => (<PageMenuAssembly                        {...p} />) },
            { name: 'MenuRegion'                         , title: 'Regions Menu'                           , comp: p => (<PageMenuRegion                          {...p} />) },
            { name: 'MenuConfRegister'                   , title: 'Conference/GBM Registrations'           , comp: p => (<PageMenuConfRegister                    {...p} />) },
            { name: 'MenuConfReception'                  , title: 'Conference/GBM Reception'               , comp: p => (<PageMenuConfReception                   {...p} />) },
            { name: 'MenuVoting'                         , title: 'GBM Voting'                             , comp: p => (<PageMenuVoting                          {...p} />) },
            //- system module -----
            { name: 'UserSearch'                         , title: 'User List'                              , comp: p => (<PageUserSearch                          {...p} />) },
            { name: 'UserEdit'                           , title: 'Edit User'                              , comp: p => (<PageUserEdit                            {...p} />) },
            { name: 'TaskSearch'                         , title: 'Task List'                              , comp: p => (<PageTaskSearch                          {...p} />) },
            { name: 'TaskEdit'                           , title: 'Edit Task'                              , comp: p => (<PageTaskEdit                            {...p} />) },
            //- pastors module -----
            { name: 'PastorSearch'                       , title: 'Pastor List'                            , comp: p => (<PagePastorSearch                        {...p} />) },
            { name: 'PastorEdit'                         , title: 'Edit Pastor'                            , comp: p => (<PagePastorEdit                          {...p} />) },
            { name: 'PastorSearchMob'                    , title: 'Pastor List'                            , comp: p => (<PagePastorSearchMob                     {...p} />) },
            { name: 'PastorDetailMob'                    , title: 'Pastor Detail'                          , comp: p => (<PagePastorDetailMob                     {...p} />) },
            { name: 'QualificationSearch'                , title: 'Pastor Qualification List'              , comp: p => (<PageQualificationSearch                 {...p} />) },
            { name: 'QualificationEdit'                  , title: 'Edit Pastor Qualification'              , comp: p => (<PageQualificationEdit                   {...p} />) },
            { name: 'PastorFileSearch'                   , title: 'Pastor File List'                       , comp: p => (<PagePastorFileSearch                    {...p} />) },
            { name: 'PastorFileEdit'                     , title: 'Edit Pastor File Edit'                  , comp: p => (<PagePastorFileEdit                      {...p} />) },
            { name: 'ReportPastorMaster'                 , title: 'Pastors Master Report'                  , comp: p => (<PageReportPastorMaster                  {...p} />) },
            { name: 'ReportPastor40Year'                 , title: 'Pastor 40 Year Report'                  , comp: p => (<PageReportPastor40Year                  {...p} />) },
            { name: 'ReportPastoralStatus'               , title: 'Pastoral Status Report'                 , comp: p => (<PageReportPastoralStatus                {...p} />) },
            { name: 'ReportPastoralStatusLapsed'         , title: 'Pastoral Status Lapsed Report'          , comp: p => (<PageReportPastoralStatusLapsed          {...p} />) },
            { name: 'ReportPastoralStatusLapsedWarning'  , title: 'Pastoral Status Lapsed Warning Report'  , comp: p => (<PageReportPastoralStatusLapsedWarning   {...p} />) },
            { name: 'ReportPastorDuplicates'             , title: 'Pastor Duplicates Report'               , comp: p => (<PageReportPastorDuplicates              {...p} />) },
            { name: 'ReportPastorGender'                 , title: 'Pastor Gender Report'                   , comp: p => (<PageReportPastorGender                  {...p} />) },
            { name: 'ReportPastorMarriageOfficers'       , title: 'Pastor Marriage Officers Report'        , comp: p => (<PageReportPastorMarriageOfficers        {...p} />) },
            { name: 'ReportPastorMovementAccepted'       , title: 'Pastor Movement Accepted Report'        , comp: p => (<PageReportPastorMovementAccepted        {...p} />) },
            { name: 'ReportPastorMovementCalled'         , title: 'Pastor Movement Called Report'          , comp: p => (<PageReportPastorMovementCalled          {...p} />) },
            { name: 'ReportPastorReinstated'             , title: 'Pastor Reinstated Report'               , comp: p => (<PageReportPastorReinstated              {...p} />) },
            { name: 'ReportPastorRenewed'                , title: 'Pastor Renewed Report'                  , comp: p => (<PageReportPastorRenewed                 {...p} />) },
            { name: 'ReportPastorSecondedAll'            , title: 'Pastor Seconded All Report'             , comp: p => (<PageReportPastorSecondedAll             {...p} />) },
            { name: 'ReportPastorSecondedForces'         , title: 'Pastor Seconded Forces Report'          , comp: p => (<PageReportPastorSecondedForces          {...p} />) },
            { name: 'ReportPastorRetire'                 , title: 'Pastor Retire Report'                   , comp: p => (<PageReportPastorRetire                  {...p} />) },
            //- assemblies-regions module -----
            { name: 'AssemblySearch'                     , title: 'Assembly List'                          , comp: p => (<PageAssemblySearch                      {...p} />) },
            { name: 'AssemblyEdit'                       , title: 'Edit Assembly'                          , comp: p => (<PageAssemblyEdit                        {...p} />) },
            { name: 'PropertySearch'                     , title: 'Assembly Property List'                 , comp: p => (<PagePropertySearch                      {...p} />) },
            { name: 'PropertyEdit'                       , title: 'Edit Assembly Property'                 , comp: p => (<PagePropertyEdit                        {...p} />) },
            { name: 'RegionSearch'                       , title: 'Assembly Region List'                   , comp: p => (<PageRegionSearch                        {...p} />) },
            { name: 'RegionEdit'                         , title: 'Edit Region'                            , comp: p => (<PageRegionEdit                          {...p} />) },
            { name: 'ReportAssemblyMaster'               , title: 'Assemblies Master Report'               , comp: p => (<PageReportAssemblyMaster                {...p} />) },
            { name: 'ReportAssemblyAddedLast6Months'     , title: 'Assembly Added Last 6 Months Report'    , comp: p => (<PageReportAssemblyAddedLast6Months      {...p} />) },
            { name: 'ReportAssemblyNoPastors'            , title: 'Assembly No Pastors Report'             , comp: p => (<PageReportAssemblyNoPastors             {...p} />) },
            { name: 'ReportAssemblyNumberList'           , title: 'Assembly Number List Report'            , comp: p => (<PageReportAssemblyNumberList            {...p} />) },
            { name: 'ReportAssemblyNumberListSum'        , title: 'Assembly Number List Sum Report'        , comp: p => (<PageReportAssemblyNumberListSum         {...p} />) },
            { name: 'ReportAssemblyPastorsList'          , title: 'Assembly Pastors List Report'           , comp: p => (<PageReportAssemblyPastorsList           {...p} />) },
            { name: 'ReportLabelAssemblySecretary'       , title: 'Label Assembly Secretary Report'        , comp: p => (<PageReportLabelAssemblySecretary        {...p} />) },
            { name: 'ReportLabelNoMailAddresses'         , title: 'Label No Mail Addresses Report'         , comp: p => (<PageReportLabelNoMailAddresses          {...p} />) },
            { name: 'ReportRegionList'                   , title: 'Region List Report'                     , comp: p => (<PageReportRegionList                    {...p} />) },
            { name: 'ReportRegionOfficeBearers'          , title: 'Region Office Bearers Report'           , comp: p => (<PageReportRegionOfficeBearers           {...p} />) },
            //- justice module -----
            { name: 'JusticeSearch'                      , title: 'Justice List'                           , comp: p => (<PageJusticeSearch                       {...p} />) },
            { name: 'JusticeEdit'                        , title: 'Edit Justice Case'                      , comp: p => (<PageJusticeEdit                         {...p} />) },
            { name: 'JusticeNoteSearch'                  , title: 'Justice Note List'                      , comp: p => (<PageJusticeNoteSearch                   {...p} />) },
            { name: 'JusticeNoteEdit'                    , title: 'Edit Justice Case Note'                 , comp: p => (<PageJusticeNoteEdit                     {...p} />) },
            { name: 'JusticeFileSearch'                  , title: 'Justice File List'                      , comp: p => (<PageJusticeFileSearch                   {...p} />) },
            { name: 'JusticeFileEdit'                    , title: 'Edit Justice File'                      , comp: p => (<PageJusticeFileEdit                     {...p} />) },
            //- conf registration module -----
            { name: 'AttendeeNewOnline'                  , title: 'GBM 2024 Registration'                  , comp: p => (<PageAttendeeNewOnline                   {...p} url={url} />) },
            { name: 'AttendeeSearch'                     , title: 'Attendee List'                          , comp: p => (<PageAttendeeSearch                      {...p} />) },
            { name: 'AttendeeEdit'                       , title: 'Edit Attendee'                          , comp: p => (<PageAttendeeEdit                        {...p} />) },
            { name: 'ConfregisStatistics'                , title: 'Registration Statistics'                , comp: p => (<PageConfregisStatistics                 {...p} />) },
            { name: 'ConfregisCards'                     , title: 'Registrations Cards'                    , comp: p => (<PageConfregisCards                      {...p} />) },
            { name: 'ConfregisCardsExtra'                , title: 'Registrations Cards Extra'              , comp: p => (<PageConfregisCardsExtra                 {...p} />) },
            { name: 'ReportConfregisSummaryTotal'        , title: 'Summary Totals Report'                  , comp: p => (<PageReportConfregisSummaryTotal         {...p} />) },
            { name: 'ReportConfregisAttendees'           , title: 'Attendees List Report'                  , comp: p => (<PageReportConfregisAttendees            {...p} />) },
            { name: 'ReportConfregisByRegion'            , title: 'Attendees by Region Report'             , comp: p => (<PageReportConfregisByRegion             {...p} />) },
            { name: 'ReportConfregisByType'              , title: 'Attendees by Type Report'               , comp: p => (<PageReportConfregisByType               {...p} />) },
            { name: 'ReportConfregisByRegionNoId'        , title: 'Attendees by Region No ID Report'       , comp: p => (<PageReportConfregisByRegionNoId         {...p} />) },            
            { name: 'ReportConfregisQuorumDetail'        , title: 'Quorum Detail Report'                   , comp: p => (<PageReportConfregisQuorumDetail         {...p} />) },
            { name: 'ReportConfregisQuorumTotal'         , title: 'Quorum Totals Report'                   , comp: p => (<PageReportConfregisQuorumTotal          {...p} />) },
            { name: 'ReportConfregisSummary'             , title: 'Attendees Summary Report'               , comp: p => (<PageReportConfregisSummary              {...p} />) },
            //- conf reception module -----
            { name: 'ConfrecepStatistics'                , title: 'Reception Statistics'                   , comp: p => (<PageConfrecepStatistics                 {...p} />) },
            { name: 'ConfrecepScan'                      , title: 'Reception Scanning'                     , comp: p => (<PageConfrecepScan                       {...p} />) },
            { name: 'ConfrecepGoodyScan'                 , title: 'Goody-Bag Scanning'                     , comp: p => (<PageConfrecepGoodyScan                  {...p} />) },
            { name: 'ReportConfrecepSummary'             , title: 'Reception Summary'                      , comp: p => (<PageReportConfrecepSummary              {...p} />) },
            { name: 'ReportConfrecepDetail'              , title: 'Reception Breakdown'                    , comp: p => (<PageReportConfrecepDetail               {...p} />) },
            //- voting module -----
            { name: 'VoteProcessManager'                 , title: 'Process Manager'                        , comp: p => (<PageVoteProcessManager                  {...p} />) },
            { name: 'VoteCaptureNomin'                   , title: 'Capture Nominations'                    , comp: p => (<PageVoteCaptureNomin                    {...p} />) },
            { name: 'VoteApprovalPrimary'                , title: 'Primary Approvals'                      , comp: p => (<PageVoteApprovalPrimary                 {...p} />) },
            { name: 'VoteApprovalSecondary'              , title: 'Secondary Approvals'                    , comp: p => (<PageVoteApprovalSecondary               {...p} />) },
            { name: 'VoteApprovalFinal'                  , title: 'Final Approvals'                        , comp: p => (<PageVoteApprovalFinal                   {...p} />) },
            { name: 'VoteDisplayResults'                 , title: 'Voting Round Results'                   , comp: p => (<PageVoteDisplayResults                  {...p} />) },
            { name: 'VoteDisplayStatus'                  , title: 'Voting Status'                          , comp: p => (<PageVoteDisplayStatus                   {...p} />) },
            { name: 'ReportVoteResults'                  , title: 'Report: Voting Results'                 , comp: p => (<PageReportVoteResults                   {...p} />) },
        ]}
    />);
}
